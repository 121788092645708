import { Formik, Form, ErrorMessage, Field } from "formik";
import { useStaticQuery, graphql, Link } from "gatsby";
import axios from "axios";
import { RadioGroup } from "@headlessui/react";

import React, { useState } from "react";
import Layout from "../components/Global/layout";
import SEO from "../components/seo/SEO";
import * as Yup from "yup";
import Alert from "../components/alert";
import formErrors from "../components/Global/formErrors";
import { StaticImage } from "gatsby-plugin-image";
import BookingForm from "../components/booking/bookingForm";

const Booking = () => {
  const [bike, setBike] = useState("");
  const [color, setColor] = useState("");
  const [isSent, setIsSent] = useState(false);

  const submitForm = (values) => {
    axios({
      headers: {
        "Content-Type": "application/json",
      },
      method: "post",
      url: "https://hook.integromat.com/gxz7yrkcvks0fe9peu1exmixuuizvxnc",
      data: JSON.stringify(values),
    })
      .then((r) => {
        console.log("Thanks!", values);
        window.location.replace("https://imjo.in/3kc45f");
      })
      .catch((r) => {
        console.log("Failed", values);
      });
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("This Field is Required"),
    lastName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter valid name")
      .required("This Field is Required"),
    eMail: Yup.string()
      .email("Please give your E-Mail")
      .required("Please fill your E-Mail Address"),
    mobileNumber: Yup.string()
      .matches(/^[6-9]\d{9}$/, {
        message: "Please enter valid number.",
        excludeEmptyString: false,
      })
      .required("Mobile Number is Required"),
    cityName: Yup.string()
      .matches(/^[A-Za-z ]*$/, "Please enter a valid City Name")
      .required("This Field is Required"),
  });

  return (
    <>
      <SEO />
      <Layout>
        <div className="w-screen h-screen">
          <main className="contact-options-grid h-full">
            <div className="py-2 bg-gray-900"></div>
            <div className="bg-gray-900 h-full p-8 md:p-12">
              <div className="flex flex-col space-y-4">
                <h1 className="text-4xl text-blue-500 font-medium text-center">
                  Book your Electric Bike
                </h1>
              </div>
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900">
              <h2 className="text-2xl text-red-300 text-center p-4">The Bookings are now closed, <br className="hidden sm:block"/>Please stay tuned for more updates.</h2>
              {/* <RadioGroup
                value={bike}
                onChange={setBike}
                className="p-4 md:p-8 lg:p-12 flex flex-col space-y-4 transition-all duration-300 ease-out delay-150"
              >
                <RadioGroup.Label className="text-3xl text-gray-400 py-4">
                  Choose your Bike
                </RadioGroup.Label>
                <div className="flex flex-col md:flex-row space-y-4 md:space-x-2 md:space-y-0 space-x-0">
                  <RadioGroup.Option
                    value="KM3000"
                    className={({ active, checked }) =>
                      `${
                        active
                          ? "ring-2 ring-offset-2 ring-offset-blue-300 ring-blue-800 ring-opacity-60"
                          : ""
                      }
              ${
                checked
                  ? "bg-blue-700 transition-all duration-500 ease-out bg-opacity-75 text-white scale-[102%] shadow-2xl"
                  : "bg-gray-800"
              }
                rounded-lg  w-full shadow-md cursor-pointer flex flex-col space-y-2 items-center focus:outline-none pt-10`
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <RadioGroup.Label
                          as="p"
                          className={`${
                            checked
                              ? "text-3xl transition-all duration-300 ease-out py-4 font-medium text-center text-white"
                              : "text-3xl  text-blue-500 text-center"
                          }`}
                        >
                          KM3000
                          <div className="flex flex-col space-y-6">
                            <div className="flex flex-row space-x-5 pt-4">
                              <ul className="flex flex-col spacy-y-2 items-center">
                                <li className="text-xl">Speed</li>
                                <li className="text-xl">100kmph</li>
                              </ul>
                              <ul className="flex flex-col spacy-y-2 items-center">
                                <li className="text-xl">Range</li>
                                <li className="text-xl">120km</li>
                              </ul>
                            </div>
                            <ul className="flex flex-col spacy-y-4 items-center ">
                              <li className="text-xl">Booking Amount</li>
                              <li className="text-xl font-light">₹2,499</li>
                            </ul>
                          </div>
                        </RadioGroup.Label>
                        <StaticImage
                          src="https://res.cloudinary.com/kabira-mobility/image/upload/v1639782596/Booking%20Page/bookingKM3000_maa1lh.png"
                          className="w-full bg-transparent"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                  <RadioGroup.Option
                    value="KM4000"
                    className={({ active, checked }) =>
                      `${
                        active
                          ? "ring-2 ring-offset-2 ring-offset-blue-300 ring-blue-800 ring-opacity-60"
                          : ""
                      }
                ${
                  checked
                    ? "bg-blue-700 transition-all duration-500 ease-out bg-opacity-75 text-white scale-[102%] shadow-2xl"
                    : "bg-gray-800"
                }
                  rounded-lg  w-full shadow-md cursor-pointer flex flex-col space-y-2 items-center focus:outline-none pt-10`
                    }
                  >
                    {({ active, checked }) => (
                      <>
                        <RadioGroup.Label
                          as="p"
                          className={`${
                            checked
                              ? "text-3xl transition-all duration-300 ease-out py-4 font-medium text-center text-white"
                              : "text-3xl  text-blue-500 text-center"
                          }`}
                        >
                          KM4000
                          <div className="flex flex-col space-y-6">
                            <div className="flex flex-row space-x-5 pt-4">
                              <ul className="flex flex-col spacy-y-2 items-center">
                                <li className="text-xl">Speed</li>
                                <li className="text-xl">120kmph</li>
                              </ul>
                              <ul className="flex flex-col spacy-y-2 items-center">
                                <li className="text-xl">Range</li>
                                <li className="text-xl">150km</li>
                              </ul>
                            </div>
                            <ul className="flex flex-col spacy-y-4 items-center ">
                              <li className="text-xl">Booking Amount</li>
                              <li className="text-xl font-light">₹2,499</li>
                            </ul>
                          </div>
                        </RadioGroup.Label>
                        <StaticImage
                          src="https://res.cloudinary.com/kabira-mobility/image/upload/v1639782596/Booking%20Page/bookingKM4000_cze4jv.png"
                          className="w-full bg-transparent"
                        />
                      </>
                    )}
                  </RadioGroup.Option>
                </div>
              </RadioGroup> */}
              {bike === "KM3000" ? (
                <>
                  <div className="flex flex-col p-4 md:p-8 lg:p-12 divide-y-[1px] divide-gray-700 divide-dashed">
                    <h3 className="text-3xl text-gray-400 py-4">
                      Price Breakdown
                    </h3>
                    <ul className="flex flex-row justify-between items-center py-5">
                      <li className="text-base md:text-lg text-gray-300">
                        Ex-Factory Price (Goa)
                      </li>
                      <li className="text-base md:text-lg text-gray-300">
                        ₹164,640
                      </li>
                    </ul>
                    <ul className="flex flex-row justify-between py-5 items-center">
                      <li className="text-base md:text-lg text-green-400">
                        FAME-II Subsidy
                      </li>
                      <li className="text-base md:text-lg text-green-400">
                        (-) ₹42,000
                      </li>
                    </ul>
                    <ul className="flex flex-row justify-between py-5 items-center">
                      <li className="text-base md:text-lg text-green-400">
                        State Subsidy
                      </li>
                      <li className="text-base md:text-lg text-green-400">
                        At Actual
                      </li>
                    </ul>
                    <ul className="flex flex-row justify-between py-5 items-center">
                      <li className="text-base md:text-lg text-gray-300">
                        Ex-Factory Price (Incl Subsidy)
                      </li>
                      <li className="text-base md:text-lg text-gray-300">
                        ₹122,640
                      </li>
                    </ul>
                    <ul className="flex flex-row justify-between py-5 items-center">
                      <li className="text-base md:text-lg text-yellow-300">
                        Booking Amount
                      </li>
                      <li className="text-base md:text-lg text-yellow-300">
                        ₹2,499
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <></>
              )}
              {bike === "KM4000" ? (
                <>
                  <div className="flex flex-col p-4 md:p-8 lg:p-12 divide-y-[1px] divide-gray-700 divide-dashed">
                    <h3 className="text-3xl text-gray-400 py-4">
                      Price Breakdown
                    </h3>
                    <ul className="flex flex-row justify-between items-center py-5">
                      <li className="text-base md:text-lg text-gray-300">
                        Ex-Factory Price (Goa)
                      </li>
                      <li className="text-base md:text-lg text-gray-300">
                        ₹167,580
                      </li>
                    </ul>
                    <ul className="flex flex-row justify-between py-5 items-center">
                      <li className="text-base md:text-lg text-green-400">
                        FAME-II Subsidy
                      </li>
                      <li className="text-base md:text-lg text-green-400">
                        (-) ₹42,000
                      </li>
                    </ul>
                    <ul className="flex flex-row justify-between py-5 items-center">
                      <li className="text-base md:text-lg text-green-400">
                        State Subsidy
                      </li>
                      <li className="text-base md:text-lg text-green-400">
                        At Actual
                      </li>
                    </ul>
                    <ul className="flex flex-row justify-between py-5 items-center">
                      <li className="text-base md:text-lg text-gray-300">
                        Ex-Factory Price (Incl Subsidy)
                      </li>
                      <li className="text-base md:text-lg text-gray-300">
                        ₹125,580
                      </li>
                    </ul>
                    <ul className="flex flex-row justify-between py-5 items-center">
                      <li className="text-base md:text-lg text-yellow-300">
                        Booking Amount
                      </li>
                      <li className="text-base md:text-lg text-yellow-300">
                        ₹2,499
                      </li>
                    </ul>
                  </div>
                </>
              ) : (
                <></>
              )}
              {bike === "KM3000" ? (
                <>
                  <RadioGroup
                    value={color}
                    onChange={setColor}
                    className="p-4 md:p-8 lg:p-12 pt-0 flex flex-col space-y-4 transition-all duration-300 ease-out delay-150"
                  >
                    <RadioGroup.Label className="text-3xl text-gray-400 py-4">
                      Select your Color
                    </RadioGroup.Label>
                    <div className="flex flex-col md:flex-row space-y-4 md:space-x-2 md:space-y-0 space-x-0">
                      <RadioGroup.Option
                        value="Glossy Red"
                        className={({ active, checked }) =>
                          `${
                            active
                              ? "ring-2 ring-offset-2 ring-offset-blue-300 ring-blue-800 ring-opacity-60"
                              : ""
                          }
                ${
                  checked
                    ? "bg-red-800/40 transition-all duration-500 ease-out bg-opacity-75 text-white shadow-2xl"
                    : ""
                }
                  rounded-lg bg-gray-800 w-full shadow-md cursor-pointer flex flex-col space-y-2 items-center focus:outline-none relative p-6`
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="h-20 w-20 bg-red-500 rounded-full"></div>
                            <RadioGroup.Label
                              as="p"
                              className={`${
                                checked
                                  ? "text-2xl transition-all duration-300 ease-out py-4 font-medium"
                                  : "text-2xl py-4 font-light text-gray-400"
                              }`}
                            >
                              Glossy Red
                            </RadioGroup.Label>
                          </>
                        )}
                      </RadioGroup.Option>
                      <RadioGroup.Option
                        value="Glossy Black"
                        className={({ active, checked }) =>
                          `${
                            active
                              ? "ring-2 ring-offset-2 ring-offset-blue-300 ring-blue-800 ring-opacity-60"
                              : ""
                          }
                ${
                  checked
                    ? "bg-gray-800/40 transition-all duration-500 ease-out bg-opacity-75 text-white shadow-2xl"
                    : ""
                }
                  rounded-lg bg-gray-800 w-full shadow-md cursor-pointer flex flex-col space-y-2 items-center focus:outline-none relative p-6`
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="h-20 w-20 bg-black rounded-full"></div>
                            <RadioGroup.Label
                              as="p"
                              className={`${
                                checked
                                  ? "text-2xl transition-all duration-300 ease-out py-4 font-medium"
                                  : "text-2xl py-4 font-light text-gray-400"
                              }`}
                            >
                              Glossy Black
                            </RadioGroup.Label>
                          </>
                        )}
                      </RadioGroup.Option>
                    </div>
                  </RadioGroup>
                </>
              ) : (
                <></>
              )}
              {bike === "KM4000" ? (
                <>
                  <RadioGroup
                    value={color}
                    onChange={setColor}
                    className="p-4 md:p-8 lg:p-12 pt-0 flex flex-col space-y-4 transition-all duration-300 ease-out delay-150"
                  >
                    <RadioGroup.Label className="text-3xl text-gray-400 py-4">
                      Select your Color
                    </RadioGroup.Label>
                    <div className="flex flex-col md:flex-row space-y-4 md:space-x-2 md:space-y-0 space-x-0">
                      {/* <RadioGroup.Option
                        value="Glossy Red"
                        className={({ active, checked }) =>
                          `${
                            active
                              ? "ring-2 ring-offset-2 ring-offset-blue-300 ring-blue-800 ring-opacity-60"
                              : ""
                          }
                ${
                  checked
                    ? "bg-red-800/40 transition-all duration-500 ease-out bg-opacity-75 text-white shadow-2xl"
                    : ""
                }
                  rounded-lg bg-gray-800 w-full shadow-md cursor-pointer flex flex-col space-y-2 items-center focus:outline-none relative p-6`
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="h-20 w-20 bg-red-500 rounded-full"></div>
                            <RadioGroup.Label
                              as="p"
                              className={`${
                                checked
                                  ? "text-2xl transition-all duration-300 ease-out py-4 font-medium"
                                  : "text-2xl py-4 font-light text-gray-400"
                              }`}
                            >
                              Glossy Red
                            </RadioGroup.Label>
                          </>
                        )}
                      </RadioGroup.Option> */}
                      <RadioGroup.Option
                        value="Glossy Green"
                        className={({ active, checked }) =>
                          `${
                            active
                              ? "ring-2 ring-offset-2 ring-offset-blue-300 ring-blue-800 ring-opacity-60"
                              : ""
                          }
                ${
                  checked
                    ? "bg-green-500/40 transition-all duration-500 ease-out bg-opacity-75 text-white shadow-2xl"
                    : ""
                }
                  rounded-lg bg-gray-800 w-full shadow-md cursor-pointer flex flex-col space-y-2 items-center focus:outline-none relative p-6`
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="h-20 w-20 bg-green-500 rounded-full"></div>
                            <RadioGroup.Label
                              as="p"
                              className={`${
                                checked
                                  ? "text-2xl transition-all duration-300 ease-out py-4 font-medium"
                                  : "text-2xl py-4 font-light text-gray-400"
                              }`}
                            >
                              Glossy Green
                            </RadioGroup.Label>
                          </>
                        )}
                      </RadioGroup.Option>
                      <RadioGroup.Option
                        value="Glossy Black"
                        className={({ active, checked }) =>
                          `${
                            active
                              ? "ring-2 ring-offset-2 ring-offset-blue-300 ring-blue-800 ring-opacity-60"
                              : ""
                          }
                ${
                  checked
                    ? "bg-gray-800/40 transition-all duration-500 ease-out bg-opacity-75 text-white shadow-2xl"
                    : ""
                }
                  rounded-lg bg-gray-800 w-full shadow-md cursor-pointer flex flex-col space-y-2 items-center focus:outline-none relative p-6`
                        }
                      >
                        {({ active, checked }) => (
                          <>
                            <div className="h-20 w-20 bg-black rounded-full"></div>
                            <RadioGroup.Label
                              as="p"
                              className={`${
                                checked
                                  ? "text-2xl transition-all duration-300 ease-out py-4 font-medium"
                                  : "text-2xl py-4 font-light text-gray-400"
                              }`}
                            >
                              Glossy Black
                            </RadioGroup.Label>
                          </>
                        )}
                      </RadioGroup.Option>
                    </div>
                  </RadioGroup>
                </>
              ) : (
                <></>
              )}
              {!color ? (
                <></>
              ) : (
                <>
                  {!isSent ? (
                    <Formik
                      initialValues={{
                        bikeColor: color,
                        bikeName: bike,
                        cityName: "",
                        firstName: "",
                        lastName: "",
                        eMail: "",
                        mobileNumber: "",
                      }}
                      onSubmit={(values, actions) => {
                        submitForm(values);
                        console.log(values);
                        actions.setSubmitting(false);
                        actions.resetForm();
                        setIsSent(true);
                      }}
                      validationSchema={validationSchema}
                    >
                      <Form className="form-grid">
                        <h3 className="text-3xl text-gray-400 py-4 col-span-2">
                          Contact Details
                        </h3>
                        <div className="form-field-container">
                          <label htmlFor="firstName" className="form-label">
                            First Name
                          </label>
                          <Field
                            type="text"
                            id="firstName"
                            name="firstName"
                            placeholder="First Name"
                            className="form-input-field"
                          />
                          <ErrorMessage name="firstName" component={formErrors} />
                        </div>
                        <div className="form-field-container">
                          <label htmlFor="lastName" className="form-label">
                            Last Name
                          </label>
                          <Field
                            type="text"
                            id="lastName"
                            name="lastName"
                            placeholder="Last Name"
                            className="form-input-field"
                          />
                          <ErrorMessage name="lastName" component={formErrors} />
                        </div>
                        <div className="form-field-container">
                          <label htmlFor="eMail" className="form-label">
                            E-Mail Address
                          </label>
                          <Field
                            type="text"
                            id="eMail"
                            name="eMail"
                            placeholder="E-Mail Address"
                            className="form-input-field"
                          />
                          <ErrorMessage name="eMail" component={formErrors} />
                        </div>
                        <div className="form-field-container">
                          <label htmlFor="mobileNumber" className="form-label">
                            Mobile Number
                          </label>
                          <ul className="flex flex-row">
                            <li className="flex flex-col items-center justify-center px-3 rounded-l bg-gray-800 shadow border-gray-700 border border-r-0 text-gray-400">
                              +91
                            </li>
                            <li className="flex-1">
                              <Field
                                type="text"
                                id="mobileNumber"
                                name="mobileNumber"
                                placeholder="91234567890"
                                className="form-input-field rounded-r rounded-l-none"
                              />
                            </li>
                          </ul>
                          <ErrorMessage
                            name="mobileNumber"
                            component={formErrors}
                          />
                        </div>
                        <div className="form-field-container">
                          <label htmlFor="cityName" className="form-label">
                            City
                          </label>
                          <Field
                            type="text"
                            id="cityName"
                            name="cityName"
                            placeholder="City"
                            className="form-input-field"
                          />
                          <ErrorMessage name="cityName" component={formErrors} />
                        </div>
                        <div className="form-field-container col-span-2 flex flex-row justify-end">
                          <button
                            type="submit"
                            className="contact-form-button border-r-0"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    </Formik>
                  ) : (
                    <div className="flex flex-col items-center justify-center py-16 px-8">
                      <p className=" text-3xl text-blue-400 text-center">
                        {" "}
                        Thank you For Submitting the Form,
                      </p>
                      <p className="pt-4 text-xl font-light text-red-500 animate-pulse text-center">
                        You will be redirected to the Payment Gateway Shortly, Do
                        not press the back button.
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="py-2 bg-gray-900"></div>
            <div className="py-2 bg-gray-900"></div>
            <div className="bg-gray-900 h-full">
              {/* <div className="flex flex-col space-y-12">
                <h3 className="text-4xl text-blue-500 font-medium">F.A.Q.</h3>
                <div className="flex flex-col space-y-3">
                  <ul className="flex flex-col space-y-3 border-t-[1px] border-gray-700 border-dashed py-8">
                    <li className="text-2xl text-gray-300">
                      When will I get the Bikes Delivered?
                    </li>
                    <li className="text-lg text-gray-400 font-light leading-relaxed">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Adipisci maiores ea accusantium blanditiis et accusamus
                      repellendus quis amet unde eos, repellat voluptatem
                      recusandae sunt possimus omnis fuga fugiat temporibus modi?
                    </li>
                  </ul>
                  <ul className="flex flex-col space-y-3 border-t-[1px] border-gray-700 border-dashed py-8">
                    <li className="text-2xl text-gray-300">
                      When will I get the Bikes Delivered?
                    </li>
                    <li className="text-lg text-gray-400 font-light leading-relaxed">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Adipisci maiores ea accusantium blanditiis et accusamus
                      repellendus quis amet unde eos, repellat voluptatem
                      recusandae sunt possimus omnis fuga fugiat temporibus modi?
                    </li>
                  </ul>
                  <ul className="flex flex-col space-y-3 border-t-[1px] border-gray-700 border-dashed py-8">
                    <li className="text-2xl text-gray-300">
                      When will I get the Bikes Delivered?
                    </li>
                    <li className="text-lg text-gray-400 font-light leading-relaxed">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Adipisci maiores ea accusantium blanditiis et accusamus
                      repellendus quis amet unde eos, repellat voluptatem
                      recusandae sunt possimus omnis fuga fugiat temporibus modi?
                    </li>
                  </ul>
                  <ul className="flex flex-col space-y-3 border-t-[1px] border-gray-700 border-dashed py-8">
                    <li className="text-2xl text-gray-300">
                      When will I get the Bikes Delivered?
                    </li>
                    <li className="text-lg text-gray-400 font-light leading-relaxed">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Adipisci maiores ea accusantium blanditiis et accusamus
                      repellendus quis amet unde eos, repellat voluptatem
                      recusandae sunt possimus omnis fuga fugiat temporibus modi?
                    </li>
                  </ul>
                  <ul className="flex flex-col space-y-3 border-t-[1px] border-gray-700 border-dashed py-8">
                    <li className="text-2xl text-gray-300">
                      When will I get the Bikes Delivered?
                    </li>
                    <li className="text-lg text-gray-400 font-light leading-relaxed">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Adipisci maiores ea accusantium blanditiis et accusamus
                      repellendus quis amet unde eos, repellat voluptatem
                      recusandae sunt possimus omnis fuga fugiat temporibus modi?
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="py-2 bg-gray-900"></div>
          </main>
        </div>
      </Layout>
    </>
  );
};

export default Booking;

import React from 'react'
import PropTypes from "prop-types"


const Alert = ({ type, message, severity }) => {
    if (message.length === 0) return null
    const className = `sm:w-full md:w-full lg:w-auto sm:mx-4 md:mx-8 lg:mx-16 my-6 p-2 border rounded-md ${type == 'error' ? 'bg-red-200 text-red-500' : 'bg-green-200 text-green-500'}`
    return (
        <div className={className}>
            {message}
        </div>
    )
}


export default Alert

Alert.propTypes = {
    type: PropTypes.string,
    message: PropTypes.string,
    severity: PropTypes.string
}

Alert.defaultProps = {
    type: 'error',
    message: null,
    severity: null
}

